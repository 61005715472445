import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Heroarea from "./components/Heroarea";
import AboutUs from "./components/about_us";

import Contentleft from "./components/Contentleft";
import Contentright from "./components/Contentright";
import Testimonial from "./components/Testimonial";
import Faq from "./components/Faq";
import Cta from "./components/Cta";
import Footer from "./components/Footer";
import text from "./constants/text.json";
import { imageObject } from "./constants/image";
import styles from "./components/Navbar.module.css";
import color from "./constants/colors.json";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { db } from "./firebase";
function App() {
  const [isRtl, setIsRtl] = useState(false);
  const [content, setContent] = useState({});
  const toggleRtl = () => {
    setIsRtl(true);
  };
  useEffect(() => {
    async function getWebsiteContent() {
      try {
        const querySnapshot = await getDocs(collection(db, "admin"));
        if (!querySnapshot.empty) {
          const docSnapshot = querySnapshot.docs[0];
          setContent({ id: docSnapshot.id, ...docSnapshot.data() });
        } else {
          getWebsiteContent();
          console.log("No documents found in the 'admin' collection.");
        }
      } catch (error) {
        getWebsiteContent();

        console.log("Error: ", error);
      }
    }
    getWebsiteContent();
  }, []);
  return (
    <div className={`App ${isRtl ? "rtl" : ""}`}>
      <link rel="shortcut icon" href="image/favicon.jpg" type="image/x-icon" />
      {/* Bootstrap, fonts & icons */}
      <link rel="stylesheet" href="./css/bootstrap.css" />
      <link rel="stylesheet" href="./fonts/icon-font/css/style.css" />
      <link rel="stylesheet" href="./fonts/typography-font/typo.css" />
      <link rel="stylesheet" href="./fonts/fontawesome-5/css/all.css" />
      <link
        href="https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500;700;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap"
        rel="stylesheet"
      />

      {isRtl ? (
        <div className="App-content">
          {/* <Navbar
            toggleRtl={toggleRtl}
            isRtl={true}
            contact={text.Arabic4}
            download={text.Arabicdescription4}
            image={imageObject.image4}
            about={text.Arabic9}
            features={text.Arabic10}
            policy={text.Arabic11}
            reserved={text.Arabic12}
            privacyPolicyContent={content.privacy_policy}
          /> */}
           <AboutUs
            title={text.Arabic9}
            description={content?.about_us}
          />
          <Heroarea title={text.Arabic3} description={text.Arabicdescription3} image={imageObject.image3} />
          <Contentleft title={text.Arabic} description={text.Arabicdescription} image={imageObject.image} />
          <Contentright
            title={text.Arabic1}
            description={text.Arabicdescription1}
            image={imageObject.image1}
          />
          <Contentleft
            title={text.Arabic2}
            description={text.Arabicdescription2}
            image={imageObject.image2}
          />
          {/*<Testimonial heading={text.Arabicheading} title={text.Arabic5} name={text.Arabicname5} designation={text.Arabicdesignation5} title6={text.Arabic6} name6={text.Arabicname6} designation6={text.Arabicdesignation6} title7={text.Arabic7} name7={text.Arabicname7} designation7={text.Arabicdesignation7}/>
           */}{" "}
          <Faq isRtl={isRtl} faqheading={text.Arabicfaq} />
          <Cta title={text.Arabic8} description={text.Arabicdescription8} />
          <Footer
            about={text.Arabic9}
            features={text.Arabic10}
            policy={text.Arabic11}
            contact={text.Arabic4}
            reserved={text.Arabic12}
            image={imageObject.image4}
          />
        </div>
      ) : (
        <>
          {/* <Navbar
            toggleRtl={toggleRtl}
            isRtl={isRtl}
            contact={text.Arabic4}
            download={text.Arabicdescription4}
            image={imageObject.image4}
            about={text.Arabic9}
            features={text.Arabic10}
            policy={text.Arabic11}
            reserved={text.Arabic12}
            privacyPolicyContent = {content.privacy_policy}

          /> */}
          
          <div className={styles.mobile} >     
            <div >
              <a href="#">
                <img src={imageObject.image5} alt="logo" style={{  
                  width: "100%" , scale: "1.5" ,  padding : "15px 0px 0px 0px" ,
                
                }}  />
              </a>
            </div>
          </div><div className={styles.desktop}>     
            <div  style={{   width: "100%",background: color.primary }}>
              <a href="#">
                <img  src={imageObject.image5} alt="logo" style={{  
                  width: "100%",
                  
                }} />
              </a>
            </div>
          </div>
          <AboutUs
            title={text.Arabic9}
            description={content?.about_us}
          />
          {/* <Heroarea
            title={content?.bodyContent?.[0]?.title}
            description={content?.bodyContent?.[0].description}
            image={imageObject.image3}
          />
          <Contentleft
            title={content?.bodyContent?.[1].title}
            description={content?.bodyContent?.[1].description}
            image={imageObject.image}
          />
          <Contentright
            title={content?.bodyContent?.[2].title}
            description={content?.bodyContent?.[2].description}
            image={imageObject.image1}
          />
          <Contentleft
            title={content?.bodyContent?.[3].title}
            description={content?.bodyContent?.[3].description}
            image={imageObject.image2}
          /> */}
          {/* <Testimonial heading={text.Engheading} title={text.Eng5} name5={text.Engname5} designation={text.Engdesignation5} title6={text.Eng6} name6={text.Engname6} designation6={text.Engdesignation6} title7={text.Eng7} name7={text.Engname7} designation7={text.Engdesignation7}/>
           */}{" "}
          <Faq faqheading={text.Arabicfaq} faqContent={content.faq || ""} />
          <Cta title={text.Arabic8} description={content.cta_content} />
          <Footer
            about={text.Arabic9}
            features={text.Arabic10}
            policy={text.Arabic11}
            contact={text.Arabic4}
            reserved={text.Arabic12}
            image={imageObject.image7}
            privacyPolicyContent = {content.privacy_policy}
          />
        </>
      )}
    </div>
  );
}

export default App;
