import React from 'react';
import favicon from '../images/favicon.jpeg';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';
export default function Footer({
  about,
  features,
  policy,
  donate,
  contact,
  reserved,
  image,
}) {
  return (
    <footer className={styles.footermain}>
      <hr></hr>
      <div className="container">
        <div className={styles.footer}>
          <div className="col-md-1">
            <div>
              <a href="#">
                <img
                  src={image}
                  width="50px"
                  alt="logo"
                  style={{ borderRadius: '10px' }}
                />
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="copyright-area-l-13 text-center text-md-right" style={{padding: "20px"}}>
              <p>{reserved}</p>
            </div>
          </div>
          <div >
              <ul className={styles.footeritems}>
                
                <li>
                  <form
                    action="https://www.paypal.com/donate"
                    method="post"
                    target="_top"
                  >
                    <input
                      type="hidden"
                      name="hosted_button_id"
                      value="7SGU24U9N2K5Q"
                    />
                    <input
                      type="image"
                      src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    />
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </li>
              </ul>
          </div>
         
        </div>
      </div>
    </footer>
  );
}
