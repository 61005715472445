import React from 'react';
import { useInView } from 'react-intersection-observer';
import app_store from '../images/app-store.png';
import google_play from '../images/google-play.png';
import styles from './Cta.module.css'
import { imageObject } from "../constants/image";

export default function Cta({title,description}) {
  const { ref, inView } = useInView({
    triggerOnce: true, 
  });
  return (
    <div
      className={`${styles.ctamain} ${inView ? styles.inView : ''}`}ref={ref} >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="row justify-content-center">
              <div className="col-xxl-7 col-xl-8 text-center">
                <div className={styles.ctacontent}>
                  <p style={ {fontSize: "25px",} }>{title}</p>
                  <p>
                    {description}
                  </p>
                  <div className={styles.app_btn}>
                    <a href="https://apps.apple.com/us/app/aneen-%D8%A3%D9%86%D9%8A%D9%86/id6455787159?uo=2" >
                      <img src={app_store} alt="" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.targetube.aneen&pli=1" >
                      <img src={google_play} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div className={styles.socialMediaIcons}>
  <a href="https://www.youtube.com/channel/UC3Mt1A5oNRuxL5LvFbql24Q" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.youtube} alt="YouTube" style={
      {width: "50px", height: "50px", marginRight: "10px"}
  }/>
  </a>

  <a href="https://x.com/Aneen3030" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.twitter} alt="X" style={
      {width: "50px", height: "50px",marginRight: "10px"}
    }/>
  </a>

  <a href="https://www.tiktok.com/@aneen3030" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.tikTok} alt="TikTok" style={
      {width: "50px", height: "50px",marginRight: "10px"}
    }/>
  </a>

  <a href="https://www.facebook.com/profile.php?id=61563768146658" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.facebook} alt="Facebook" style={
      {width: "50px", height: "50px",marginRight: "10px"}
    }/>
  </a>

  <a href="https://www.instagram.com/aneen3000/" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.instagram} alt="Instagram"  style={
      {width: "50px", height: "50px",marginRight: "10px"}
    }/>
  </a>
 
  
 
  <a href="https://www.threads.net/@aneen3000" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.threads} alt="Threads" style={
      {width: "50px", height: "50px" ,marginRight: "10px"}
    }/>
  </a>
  
  <a href="https://www.linkedin.com/in/ibrahim-elgendy-a19821280/" target="_blank" rel="noopener noreferrer">
    <img src={imageObject.linkedIn} alt="LinkedIn" style={
      {width: "50px", height: "50px",marginRight: "10px"}
    }/>
  </a>
  
</div>
    </div>
  );
}
    